import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button,Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { 
    checkIfDuplicateStrengthsExistForTheUniqueDrug, 
    refreshDuplicateStrengthStatus 
} from "./DSGWarning.actions";

export default function DSGWarning({ 
    triggerWarning, 
    dsgUUID, 
    dsoUUID, 
    workingCodexVersionId, 
    dynamicSwitchOptionDto, 
    onClose, 
    onSave 
}) {
    const history = useHistory();

    const dispatch = useDispatch();
    const showDsgWarning = useSelector((state) => state.dsgWarningSlice.showDsgWarning);
    const isLoading = useSelector((state) => state.dsgWarningSlice.isLoading);
    const existingDSI = useSelector((state) => state.dsgWarningSlice.existingDSI);

    const closeModal = () => {
        dispatch(refreshDuplicateStrengthStatus());
        onClose();
    };

    const handleCloseAndRedirect = (path) => {
        closeModal(); // Function to close the modal
        history.push(path);
        window.location.reload();
      };
      
    const onSaveModal = () => {
        dispatch(refreshDuplicateStrengthStatus());
        onSave();
    };
    
    useEffect(() => {
        if (triggerWarning) {
            dispatch(checkIfDuplicateStrengthsExistForTheUniqueDrug(dsgUUID, dsoUUID, workingCodexVersionId, dynamicSwitchOptionDto));
        }
    }, [triggerWarning]);

    return (
        <Modal show={triggerWarning} onHide={null} backdrop="static" keyboard={false} centered>
            <Modal.Header>
                <Modal.Title>Duplicate Strengths</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {isLoading ? (
                    <>
                        We are checking if the strength exists in another DSG...
                        <Spinner animation="border" size="sm" className="ms-2" />
                    </>
                    ) : showDsgWarning ? (
                    existingDSI?.dynamicSwitchGroupDto? (
                        <>
                            Duplicate strength detected in{" "}
                            <span
                                className="text-primary"
                                style={{ cursor: "pointer", textDecoration: "underline" }}
                                onClick={() => handleCloseAndRedirect(`/cat/dynamic-switch-groups/${existingDSI.dynamicSwitchGroupDto.dynamicSwitchGroupId}`)}
                            >
                                {existingDSI.dynamicSwitchGroupDto.dynamicSwitchGroupName}
                            </span>
                            </>
                    ) : (
                        <>Duplicate strength detected, but group details are unavailable.</>
                    )
                    ) : (
                    <>No duplicate strength found</>
                    )}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isLoading} variant="secondary" onClick={closeModal}>
                    Cancel
                </Button>
                <Button disabled={isLoading} variant="primary" onClick={onSaveModal}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
