import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { showNotification } from "services/NotificationService";
import { getErrorMessage } from "services/UtilityService";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";

export function activityEventEmitter(body, activityId, callbacks, errorCode="SOMETHING_WENT_WRONG") {
  sendAuthenticatedAsyncRequest(
    `/agent-workspace/${activityId}`,
    "PUT",
    body,
    (r) => {
      if (callbacks.setSubmitting) callbacks.setSubmitting(false);
      if (callbacks.closeModal) callbacks.closeModal();
      showNotification({
        title: `${body.event} Activity!`,
        message: `Successfully executed ${body.event} event on Activity!`,
        position: "tr",
        type: "success"
      });

      let isAllActivitiesSelected = false;
      if(body.switchOptions && ((body.switchOptions.length === 0 && body.activitiesList.length > 1) || body.activitiesList.length > body.switchOptions.length)){ //(ALL) || (Manullay select all activities)
        isAllActivitiesSelected = true;
      }
      const inProgressData = body.data.filter(x => AgentWorkspaceConstants.IN_PROGRESS_STATUSES.includes(x.status));

      if (callbacks.resetActivityUponClose) callbacks.resetActivityUponClose();
      if (callbacks.refreshActivityList && (inProgressData.length > 1 || body.event == 'REOPEN') && body.closeReason != 'Viewed/No Action Taken' && !isAllActivitiesSelected) callbacks.refreshActivityList();
      if (callbacks.refreshActivity) callbacks.refreshActivity();
      if (callbacks.goBackIfCallGroupClosed && body.closeReason != 'Viewed/No Action Taken' && body.event != 'REOPEN') callbacks.goBackIfCallGroupClosed();
      if ((callbacks.goBackIfNoActionTakenOrGroupClose && body.closeReason == 'Viewed/No Action Taken') || (callbacks.goBackIfNoActionTakenOrGroupClose && inProgressData.length > 1 && isAllActivitiesSelected)) callbacks.goBackIfNoActionTakenOrGroupClose();
    },
    (r) => {
      showNotification({
        title: `Unable to execute ${body.event} event on Activity!`,
        message: `${getErrorMessage(r.data) || getErrorMessage(errorCode) || "You might be working on a stale activity. Try refreshing the page."}`,
        position: "tr",
        type: "error"
      });
      if (callbacks.setSubmitting) callbacks.setSubmitting(false);
    }
  );
}