
import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';
import {
    RESET_DSG_WARNING_STATUS
} from "./DSGWarning.slice";

export const checkIfDuplicateStrengthsExistForTheUniqueDrugRequestAction='CHECK_IF_DUPLICATE_STRENGTH_EXISTS_IN_DSGS_REQUEST';
export const checkIfDuplicateStrengthsExistForTheUniqueDrugSuccessAction='CHECK_IF_DUPLICATE_STRENGTH_EXISTS_IN_DSGS_SUCCESS';
export const checkIfDuplicateStrengthsExistForTheUniqueDrugFailureAction='CHECK_IF_DUPLICATE_STRENGTH_EXISTS_IN_DSGS_FAILURE';


export const checkIfDuplicateStrengthsExistForTheUniqueDrug = (dynamicSwitchGroupUuid,dynamicSwitchOptionUuid,workingCodexVersionId,dynamicSwitchOptionDto) => (dispatch, getState) => {
  const rsaaObject = {
    [RSAA]: {
      endpoint: `${config.apiRoot}/cat/codex-version/${workingCodexVersionId}/dynamic-switch-groups/${dynamicSwitchGroupUuid}/duplicate-strengths-check/${dynamicSwitchOptionUuid}`,
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
      },
      body: JSON.stringify(dynamicSwitchOptionDto),
      types: [
        checkIfDuplicateStrengthsExistForTheUniqueDrugRequestAction,
        {
          type: checkIfDuplicateStrengthsExistForTheUniqueDrugSuccessAction,
          payload: async (action, state, res) => {
            const data = await res.json();
            if(data.dynamicSwitchGroupDto!=null){
              return data;
            }
            else{
              return "";
            }
          }
        },
        checkIfDuplicateStrengthsExistForTheUniqueDrugFailureAction
      ],
    }
  }
  return dispatch(rsaaObject);
}

export const refreshDuplicateStrengthStatus = () => (dispatch, getState) => {
  dispatch(RESET_DSG_WARNING_STATUS())
}
