export default {
  EDIT_RIGHTS_ALREADY_ACQUIRED:
    "Edit rights of the codex are already acquired by: %s",
  CANNOT_EDIT_CODEX: "Codex with status '%s', cannot be edited.",
  INVALID_CODEX: "Invalid codex version uuid: %s",
  CANNOT_RELEASE_LOCK:
    "Invalid operation: Cannot release lock acquired by another user",
  INVALID_SWITCH_UUID: "Invalid switch lookup uuid: %s",
  INVALID_SWITCH_GLOBAL_ID: "Invalid switch lookup globalId: %s",
  SAME_SOURCE_DRUG_NAME:
    "Switch lookup with the same source drug name already exists in this codex version.",
  INVALID_TARGET: "Invalid target uuid: %s",
  INVALID_SOURCE_GROUP: "Invalid source group uuid: %s",
  EMPTY_TARGET_GROUP: "Target group cannot be empty.",
  ATLEAST_ONE_TARGET_OPTION_NEEDED:
    "Must specify at least one target option for the target.",
  TARGET_GROUP_ALREADY_EXISTS_IN_CODEX:
    "Target with same target group already exists in this codex version.",
  UNKNOWN_TARGET_OPTION: "Unknown target option id: '%s'",
  EMPTY_SOURCE_GROUP: "Source group name cannot be empty.",
  SOURCE_GROUP_ALREADY_EXISTS: "Source group with same name already exists.",
  INVALID_CODEX_FOR_CUSTOMER:
    "Invalid codex version uuid: '%s' for customer uuid: '%s'",
  INVALID_DYNAMIC_SWITCH_GROUP:
    "Dynamic Switch Group not found using uuid: '%s'",
  CODEX_EDIT_ONLY_IN_INDEV_VERSION:
    "Codex can only be edited if it is in 'In Development' state.",
  UNKNOWN_CODEX_STATUS: "Unknown codex version status '%s'",
  CANNOT_CHANGE_CODEX_STATUS_WHILE_EDITING:
    "Cannot change status of codex while it is open for editing. Currently '%s' has the edit rights.",
  CANNOT_CHANGE_CODEX_STATUS_TO:
    "Cannot change the status of codex from '%s' to '%s'.",
  CANNOT_FIND_CUSTOMER: "Could not find customer with uuid: '%s'",
  CODEX_FOR_CUSTOMER_EXISTS: "Codex for customer uuid '%s' already exists.",
  UNABLE_TO_UPDATE_DYNAMIC_SWITCH_OPTION:
    "Unable to update dynamic switch option.",
  UNABLE_TO_DELETE_DYNAMIC_SWITCH_OPTION:
    "Unable to delete dynamic switch option.",
  UNABLE_TO_UPDATE_DYNAMIC_SWITCH_GROUP:
    "Unable to update dynamic switch group.",
  UNKNOWN_SOURCE_CODEX_VERSION: "Unknown source codex version uuid: '%s'.",
  CANNOT_FIND_PREVIOUS_VERSION_OF_SWITCH:
    "Cannot find previous version of switch lookup with uuid: '%s'.",
  NO_CODEX_FOR_CUSTOMER: "No codex found for customer uuid: '%s'",
  NO_TARGET_IN_SWITCH_FOR_PDF_GENERATION:
    "Switch lookup must contain a target to generate sample pdf.",
  NO_TEMPLATE_TYPE_IN_TARGET_FOR_PDF_GENERATION:
    "Target must contain template type to generate sample pdf.",
  UNABLE_TO_SEND_UPDATE_CONFIRMATION_NOTIFICATION:
    "Unable to send update confirmation notification.",
  UNABLE_TO_SEND_REGISTRATION_EMAIL:
    "Unable to send registration link email as email parameter is not found.",
  FAILED_TO_GENERATE_PDF: "Failed to process generate PDF.",
  BATCH_ALREADY_OPEN: "Batch '%s' already open. Cannot open a new batch.",
  PRESCRIBER_INFO_NOT_FOUND: "Prescriber info not found for uuid: %s",
  PHARMACY_INFO_NOT_FOUND: "Pharmacy info not found for uuid: %s",
  MEMBER_NOT_FOUND: "Member info not found for uuid: %s",
  DISALLOWED_KEYWORDL: "Following keyword(s) are not allowed: %s",
  PDF_GENERATION_ERROR_UNKNOWN_SWITCH_TYPE:
    "Error while generating pdf: unknown switch type.",
  PDF_CONTENT_OVERFLOW:
    "Content Overflow. Generated pdf cannot have more than 2 pages.",
  UNKNOWN_KEYWORD_IN_PDF_TEMPLATE: "Unknown keyword(s) in the pdf template: %s",
  UNABLE_TO_SEND_PASSWORD_UPDATE_CONFIRMATION_NOTIFICATION_NO_MEMBER_INFO:
    "Unable to send password update confirmation email to '%s' as no MemberInfo available in database.",
  UNABLE_TO_SEND_PASSWORD_UPDATE_CONFIRMATION_NOTIFICATION_NO_MEMBER_EMAIL:
    "Unable to send password update confirmation email to '%s' as no email available in database.",
  UNABLE_TO_SEND_PASSWORD_UPDATE_CONFIRMATION_NOTIFICATION_NO_CUSTOMER_INFO:
    "Unable to send password update confirmation email to '%s' as no CustomerInfo for member available in database.",
  UNABLE_TO_SEND_PASSWORD_UPDATE_CONFIRMATION_NOTIFICATION_NO_WORKFLOW_CUSTOMER_INFO:
    "Unable to send password update confirmation email to '%s' as no workflow CustomerInfo for member available in database.",
  UNABLE_TO_SEND_PASSWORD_UPDATE_CONFIRMATION_NOTIFICATION_NO_USECASE_TEMPLATE:
    "Unable to send password update confirmation notification as no usecase template found for usecase: %s",
  UNABLE_TO_SEND_EMAIL_NO_TEMPLATE_WITH_NAME:
    "Unable to send email as no template available with name: %s.",
  UNABLE_TO_SEND_PASSWORD_UPDATE_CONFIRMATION_EMAIL:
    "Unable to send password update confirmation email to '%s'.",
  UNABLE_TO_SEND_REGISTRATION_LINK_NO_MEMBER_FOUND_WITH_EMAIL:
    "Unable to send registration link email as no member with email: %s available in database.",
  UNABLE_TO_SEND_REGISTRATION_LINK_NO_CUSTOMER_INFO:
    "Unable to send registration link email to '%s' as no CustomerInfo for member available in database.",
  UNABLE_TO_SEND_REGISTRATION_LINK_NO_WORKFLOW_CUSTOMER_INFO:
    "Unable to send registration link email to '%s' as no workflow CustomerInfo for member available in database.",
  UNABLE_TO_SEND_REGISTRATION_LINK_NO_EMAIL_FOR_CUSTOMER:
    "Unable to send registration link email to '%s' as no email for customer available in database.",
  UNABLE_TO_SEND_REGISTRATION_LINK_NO_USECASE_TEMPLATE:
    "Unable to send registration link email as no usecase template found for usecase: %s",
  UNABLE_TO_SEND_EMAIL: "Unable to send email to %s",
  ONLY_MESSAGE: "%s",
  WRONG_OLD_PASSWORD: "You have entered a wrong old password.",
  INVALID_PASSWORD:
    "Password is invalid! Kindly make sure it follows all policies.",
  PASSWORD_POLICY_NOT_FETCHED: "Error while fetching password policy.",
  PASSWORD_LIMIT_EXCEEDED: "Password change limit exceeded.",
  SOMETHING_WENT_WRONG: "Something went wrong!",
  PASSWORD_CHANGE_FAILED: "Could not change password.",
  UNABLE_TO_CHANGE_CODEX_STATUS: "Unable to change codex status.",
  UNABLE_TO_REGISTER_USER: "Unable to register the user.",
  UNABLE_TO_WHITELIST_IP: "Unable to whitelist the IP Address for PHI access.",
  UNABLE_TO_DISABLE_USER: "Unable to disable the user.",
  UNABLE_TO_DELETE_USER: "Unable to delete the user.",
  UNABLE_TO_EDIT_USER: "Unable to edit the user.",
  UNABLE_TO_EDIT_CYCLE: "Unabe to edit the cycle.",
  UNABLE_TO_EDIT_BATCH: "Unable to edit the batch.",
  UNABLE_TO_REMOVE_IP: "Unable to remove the IP Address from whitelist.",
  ERROR_FETCHING_PDF: "Could not fetch pdf. Please try again after sometime",
  MEMBERS_SYNC_FAILED: "Unable to sync members.",
  FAILED_TO_UPDATE_PHARMACY_INFO: "Failed to update pharmacy info.",
  FAILED_TO_UPDATE_PRESCRIBER_INFO: "Failed to update prescriber info.",
  FAILED_TO_UPDATE_MEMBER_INFO: "Failed to update member info.",
  SWITCH_LOOKUP_DELETE_FAILED: "Unable to delete Switch Lookup.",
  ERROR_FETCHING_TARGET_GROUP: "Error fetching target group!",
  VALIDATION_ERRORS: "%s",
  SENDGRID_SETUP_ERROR:
    "Sendgrid not setup correctly for the selected customer",
  DYNAMIC_SWITCH_OPTION_ACTIVE_IN_ANOTHER_GROUP:
    "%s %s is already active in DSG %l for %s. It cannot be active in both DSGs",
  CONFLICT_PROVIDED_DATA_IN_STALE_MODE:
    "The data has been updated externally. Please reload the page and try again.",
  PAW_ITEM_NOT_FOUND: "Invalid Paw Item",
  CALL_GROUP_NOT_FOUND: "Invalid Call Group",
  ACTIVITY_NOT_FOUND: "Invalid Activity",
  INVALID_WORKFLOW_ITEM_IN_ACTIVITY:
    "Invalid Workflow Item associated with activity.",
  ERROR_CLOSING_CALL_GROUP_OPEN_ACTIVITY:
    "All activities within a call group must be closed first.",
  INVALID_RESCHEDULE_DATE:
    "Reschedule date must be after existing scheduled date.",
  USER_OWNS_ANOTHER_CALL_GROUP:
    "You cannot claim more than one call group at a time.",
  ACTIVITY_ALREADY_OPEN: "Activity already open.",
  INSTRUCTIONS_UNDEFINED_IN_FAX_GENERATION:
    "Instructions/Sigs are missing for one or more target options.",
  INVALID_ACTIVITY_EVENT: "Cannot execute %s event on the activity",
  ACTIVITY_EVENT_NOT_FOUND: "No such activity event found",
  INVALID_WFITEM_EVENT: "Invalid wf item event",
  CANNOT_SWITCH_PRESCRIBER: "%s",
  CANNOT_SWITCH_PHARMACY: "%s",
  INVALID_GLOBAL_SWITCH_ID_WHILE_CLOSING:
    "Something went wrong. Most probably provided global switch id is invalid (if any).",
  SAME_ACTIVITY_ALREADY_OPEN:
    "This or a copy of this activity is already open/on hold. Use that to perform further actions.",
  CALL_GROUP_OWNED_BY_ANOTHER_USER:
    "Call Group Owned by Another User. Kindly refresh your page.",
  CALL_GROUP_CLAIMED_BY_ANOTHER_USER:
    "This Call Group is claimed by %s. Please contact the admin to get this call group released.",
  SAME_ACTIVITY_ALREADY_HELD:
    "This or a copy of this activity is already on hold. Use that to perform further actions.",
  SAME_ACTIVITY_ALREADY_IN_NEW_STATUS:
    "This or a copy of this activity is already in NEW status. Use that to perform further actions.",
  DYNAMIC_SWITCH_OPTION_ALREADY_EXISTS_IN_CODEX:
    "%s already exists and cannot be recreated", // if the user tries to add a new DSO but that exists in the same group
  DRUG_EXISTS_IN_SAME_GROUP_WITH_SAME_ITEM_TYPE:
    "Cannot add this drug as %s in this group because it already exists", // if the drug exists in the same group with same item type
  DRUG_EXISTS_IN_OTHER_GROUP_WITH_DIFFERENT_ITEM_TYPE:
    "Some of the strengths will be disabled because this drug already exists in following groups: ", // if the drug exists in different groups
  WF_ITEM_NOT_FOUND: "Couldn't find wfItem with uuid: %s",
  GLOBAL_SWITCH_ID_NOT_FOUND: "Couldn't find Switch with global id: %s",
  UFL_STATUS_NOT_ALLOWED:
    "Follwoing switch: %s is not in DELIVERED_PRESCRIBER_FAX or EXPIRED state",
  MEMBER_ACTIVITY_CONDITIONS_NOT_SATISFIED:
    "Conditions for call member activity not satisfied: %s",
  SAVE_CLIENTS_MEMBER_COMMUNICATION_FAILED:
    "Unable to save clients member communication settings.",
  NPI_ALREADY_EXISTS: "Entity with this NPI already Exists. Enter Unique NPI.",
  ACTIVITY_VERSION_MISMATCH:
    "The activity you are trying to close is already closed with a background process.",
  CANNOT_DISPOSITION_NO_RAW_ITEM:
    "The switch is not in a state where it's disposition can be changed.",
  SAVE_CUSTOMER_AUTO_ACCEPT_SETTING_FAILED:
    "Unable to save customer auto accept settings.",
  INVALID_CYCLE_REQUEST: "Selected switches are in invalid states for Cycle",
  UNABLE_TO_CREATE_CYCLE_FOR_SELECTED_SWITCHES:
    "Could not create Cycle for selected switches.",
  CALL_GROUP_ASSIGNED_TO_ANOTHER_USER:
    "Call Group Assigned to Another User. Kindly refresh your page or unassign the user.",
  UNABLE_TO_FETCH_SWITCH_TIMELINE: "Unable to fetch switch timeline history.",
  INVALID_UNLINK_REQUEST:
    "The switch is in a status that does not allow fax unlinking.",
  UNSUPPORTED_CLOSE_REASON_FOR_SWITCH:
    "The selected close reason is not supported for the switch in its current state",
  UNABLE_TO_CREATE_MANUAL_ACTIVITY:
    "The selected switch is not valid for manual activity creation.",
  DASHBOARD_NOT_FOUND: "No such dashboard found.",
  UNABLE_TO_EDIT_DASHBOARD:
    "An error occurred while attempting to edit dashboard details.",
  NO_DRUG_GROUP_AFTER_UUID:
    "No other Open Drug Group is available that is assigned to you",
  CHANGE_TEMPLATE_NOT_SUPPORTED:
    "Template change feature is not supported on this status",
  PRESCRIBER_TASK_ACTIVITIES_ALREADY_EXISTS:
    "Error, a call cannot be created when the prescriber has an open task.%s",
  WF_ITEM_ALREADY_CLOSED_OR_EXPIRED:
    "Error cannot create an activity as the wfItem is already in a closed/expired state",
};
