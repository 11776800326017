import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from 'services/AuthService';

export const getDynamicSwitchGroup = (dynamicSwitchGroupId) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/cat/dynamic-switch-groups/${dynamicSwitchGroupId}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        'FETCH_DYNAMIC_SWITCH_GROUP_REQUEST',
        'FETCH_DYNAMIC_SWITCH_GROUP_SUCCESS',
        'FETCH_DYNAMIC_SWITCH_GROUP_FAILURE'
      ]
    }
  };
}


export const updateDynamicSwitchGroup = (workingCodexVersionId, dynamicSwitchGroupId, updatedDynamicSwitchGroup) => {
  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/cat/codex-version/${workingCodexVersionId}/dynamic-switch-groups/${dynamicSwitchGroupId}`,
      method: 'PUT',
      body:(updatedDynamicSwitchGroup),
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      types: [
        'UPDATE_DYNAMIC_SWITCH_GROUP_REQUEST',
        'UPDATE_DYNAMIC_SWITCH_GROUP_SUCCESS',
        'UPDATE_DYNAMIC_SWITCH_GROUP_FAILURE'
      ]
    }
  };
}

export const resetDsgEventRequest = () => {
  return {
    type: "RESET_DSG_EVENT_REQUEST"
  }
}

export const saveWorkingDsgInfo = (dynamicSwitchGroupId, editableBy) => {
  return {
    type: "SAVE_WORKING_DSG_INFO",
    payload: {
       dynamicSwitchGroupId, editableBy
    }
  }
}