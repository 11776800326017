import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "services/UtilityService";
import {
    checkIfDuplicateStrengthsExistForTheUniqueDrugRequestAction,
    checkIfDuplicateStrengthsExistForTheUniqueDrugSuccessAction,
    checkIfDuplicateStrengthsExistForTheUniqueDrugFailureAction
} from "./DSGWarning.actions";

const initialState = {
    dsgWarningError: "",
    existingDSI: null,
    isLoading: false
};

export const dsgWarningSlice = createSlice({
    name: "dsgWarning",
    initialState,
    reducers: {
        checkIfDuplicateStrengthsExistForTheUniqueDrugRequest: (state) => {
            state.showDsgWarning = false;
            state.isLoading = true;
            state.existingDSI = "";
        },
        checkIfDuplicateStrengthsExistForTheUniqueDrugSuccess: (state, action) => {
            state.showDsgWarning = action.payload==""?false:true;
            state.isLoading = false;
            state.existingDSI = action.payload;
        },
        checkIfDuplicateStrengthsExistForTheUniqueDrugFailure: (state, action) => {
            state.showDsgWarning = false;
            state.isLoading = false;
            state.existingDSI = getErrorMessage(action.payload.response);
        },
        RESET_DSG_WARNING_STATUS: (state) => {
            state.dsgWarningError = "";
            state.isLoading = false;
            state.existingDSI = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkIfDuplicateStrengthsExistForTheUniqueDrugRequestAction, (state, action) => {
                dsgWarningSlice.caseReducers.checkIfDuplicateStrengthsExistForTheUniqueDrugRequest(
                    state,
                    action
                );
            })
            .addCase(checkIfDuplicateStrengthsExistForTheUniqueDrugSuccessAction, (state, action) => {
                dsgWarningSlice.caseReducers.checkIfDuplicateStrengthsExistForTheUniqueDrugSuccess(
                    state,
                    action
                );
            })
            .addCase(checkIfDuplicateStrengthsExistForTheUniqueDrugFailureAction, (state, action) => {
                dsgWarningSlice.caseReducers.checkIfDuplicateStrengthsExistForTheUniqueDrugFailure(
                    state,
                    action
                );
            });
    }
});

export const {
    checkIfDuplicateStrengthsExistForTheUniqueDrugRequest,
    checkIfDuplicateStrengthsExistForTheUniqueDrugSuccess,
    checkIfDuplicateStrengthsExistForTheUniqueDrugFailure,
    RESET_DSG_WARNING_STATUS
} = dsgWarningSlice.actions;

export const dsgWarningState = (state) => state.dsgWarning;
export default dsgWarningSlice.reducer;
