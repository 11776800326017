import { Component } from "react";
import ReactDOM from "react-dom";
import { unregister } from "./registerServiceWorker";

import "assets/css/components.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/css/index.css";
import "./assets/css/variantColors.scss";
// router
import { BrowserRouter, Route, Switch } from "react-router-dom";
import indexRoutes from "./routes/Index";

// authentication
import PrivateRoute from "./components/PrivateRoute";

// redux and api middleware
import apiMiddleware from "middlewares/TokenRefreshMiddleware";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

// reducers
import unlinkDeclinedFax from "components/UnlinkDeclinedFax/UnlinkDeclinedFax.slice";
import { Reducer as userList } from "views/Admin/UserList/User.reducer";
import { Reducer as callGroupDetails } from "views/AgentWorkspace/CallGroupDetails/CallGroupDetails.reducer";
import { Reducer as callGroupList } from "views/AgentWorkspace/CallGroupList/CallGroupList.reducer";
import { Reducer as swimSwitchList } from "views/AgentWorkspace/SwimSwitchList/SwimSwitchList.reducer";
import { Reducer as transcriberList } from "views/AgentWorkspace/TranscriberList/TranscriberList.reducer";
import { Reducer as batchList } from "views/Batch/BatchList/BatchList.reducer";
import { Reducer as batchScheduler } from "views/Batch/BatchScheduler/BatchScheduler.reducer";
import { Reducer as codexVersion } from "views/Cat/CodexVersion/CodexVersion.reducer";
import { Reducer as deltaDynamicSwitchGroupList } from "views/Cat/CompareCodexVersion/UpdatedDynamicSwitchGroupList.reducer";
import { Reducer as drugSearch } from "views/Cat/DrugSearch/DrugSearch.reducer";
import { Reducer as unprocessedFax } from "views/Switch/UnprocessedFaxList/UnprocessedFaxList.reducer";
import { Reducer as dynamicSwitchGroupDetails } from "views/Cat/DynamicSwitchGroupDetails/DynamicSwitchGroupDetails.reducer";
import { Reducer as dynamicSwitchGroup } from "views/Cat/DynamicSwitchGroup/DynamicSwitchGroup.reducer";
import {Reducer as bulkActionList} from "./views/Schedular/BulkActionList/BulkActionList.reducer";
import {Reducer as schedularForm} from "./views/Schedular/SchedularForm.reducer";
import {Reducer as bulkList} from "./views/Schedular/BulkList/BulkList.reducer";
import { Reducer as dynamicSwitchGroupList } from "views/Cat/DynamicSwitchGroupList/DynamicSwitchGroupList.reducer";
import { Reducer as cycleList } from "views/Cycle/CycleList/CycleList.reducer";
import { Reducer as cycleScheduler } from "views/Cycle/CycleScheduler/CycleScheduler.reducer";
import { Reducer as landing } from "views/Landing/Landing.reducer";
import { Reducer as switchCycleList } from "views/Switch/SwitchCycleList/SwitchCycleList.reducer";
import { Reducer as switchDetails } from "views/Switch/SwitchDetails/SwitchDetails.reducer";
import { Reducer as switchList } from "views/Switch/SwitchList/SwitchList.reducer";
import unprocessedFaxDetails from "views/Switch/UnprocessedFaxDetails/UnprocessedFaxDetails.slice";
import { Reducer as dashboardMetrics } from "./components/DashboardMetrics/DashboardMetrics.reducer";
import switchActivities from "./components/MemberModule/ActivitiesPopup/ActivitiesPopup.slice";
import memberPrograms from "./components/MemberModule/CurrentPrograms/CurrentPrograms.slice";
import memberSwitches from "./components/MemberModule/MemberSwitchesGrid/MemberSwitchesGrid.slice";
import memberSpecificSwitchList from "./components/UnprocessedFaxList/MemberSwitchList.slice";
import tableOfContents from "./views/Analytics/TableOfContents/TableOfContents.slice";
import userFavoriteDashboards from "./views/Analytics/TableOfContents/UserFavoriteDashboards.slice";
import activeRadar from "./views/Cat/ActiveRadar/ActiveRadar.slice";
import { Reducer as allDrugList } from "./views/Cat/MedispanDrugs/MedispanDrugs.reducer";
import { Reducer as newDrug } from "./views/Cat/NewDrug/NewDrug.reducer";
import { Reducer as memberDetail } from "./views/MemberProfile/MemberDetail/MemberDetail.reducer";
import memberList from "./views/MemberProfile/MemberList/MemberList.slice";
import drugGroup from "./views/Paw/DrugGroup/DrugGroup.slice";
import drugGroupDetail from "./views/Paw/DrugGroupDetails/DrugGroupDetails.slice";
import pawGroupDetail from "./views/Paw/PAWGroupDisplayBar/PAWGroupDisplayBar.slice";
import pawGroup from "./views/Paw/PawGroup/PawGroup.slice";
import rawList from "./views/Raw/RawList/RawList.slice";
import pawDsgs from "./views/Paw/PawDsgs/PawDsgs.slice";
import { Reducer as switchDisposition } from "./views/Raw/SwitchDisposition/SwitchDisposition.reducer";
import dsgWarningSlice from "./views/Cat/DSGWarning/DSGWarning.slice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["codexVersion"],
  stateReconciler: hardSet,
};

// combining all reducers
const appReducer = combineReducers({
  rawList,
  switchDisposition,
  dashboardMetrics,
  switchList,
  swimSwitchList,
  switchCycleList,
  batchList,
  switchDetails,
  batchScheduler,
  cycleList,
  cycleScheduler,
  landing,
  dynamicSwitchGroupList,
  codexVersion,
  drugSearch,
  unprocessedFax,
  unprocessedFaxDetails,
  dynamicSwitchGroupDetails,
  callGroupDetails,
  callGroupList,
  deltaDynamicSwitchGroupList,
  transcriberList,
  userList,
  dynamicSwitchGroup,
  activeRadar,
  pawGroupDetail,
  allDrugList,
  newDrug,
  pawGroup,
  drugGroup,
  drugGroupDetail,
  memberList,
  tableOfContents,
  userFavoriteDashboards,
  memberPrograms,
  switchActivities,
  memberSwitches,
  memberSpecificSwitchList,
  bulkActionList,
  schedularForm,
  bulkList,
  unlinkDeclinedFax,
  memberDetail,
  dsgWarningSlice,
  pawDsgs
});

// highest level reducer
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [];

if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}

// configuring the store to save state
const stateStore = createStore(
  persistedReducer,
  applyMiddleware(apiMiddleware, thunk, ...middleware)
);

const persistor = persistStore(stateStore);

class Root extends Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Switch>
              {indexRoutes.map((prop, key) =>
                prop.isProtected ? (
                  <PrivateRoute
                    to={prop.path}
                    component={prop.component}
                    key={prop.path}
                  />
                ) : (
                  <Route
                    exact
                    path={prop.path}
                    component={prop.component}
                    key={prop.path}
                  />
                )
              )}
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

ReactDOM.render(<Root store={stateStore} />, document.getElementById("root"));

unregister();
